import { Injectable, NgZone } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

const MINUTES_UNTIL_AUTO_LOGOUT = 180; // in mins
//const MINUTES_UNTIL_AUTO_LOGOUT = 15; // in mins
const CHECK_INTERVAL = 10000; // in ms

@Injectable({
    providedIn: "root",
})
export class AutoLogoutService {

    // Getters
    get lastAction() {
        return +sessionStorage.getItem("lastAction");
    }
    set lastAction(value) {
        sessionStorage.setItem("lastAction", value.toString());
    }

    /**
     * CTOR
     * @param {OAuthService} oAuthService
     * @param {NgZone} ngZone
     * @param {RouteActions} routeActions
     */
    constructor(private oAuthService: OAuthService,
                private ngZone: NgZone)
    {
        console.log("autologout service constructor called");
        this.reset();
        this.check();
        this.initListener();
        this.initInterval();

    }

    /**
     * Initialises the click listener
     */
    initListener() {
        document.body.addEventListener("click", () => this.reset());
    }

    /**
     * Resets the countdown
     */
    reset() {
        this.lastAction = Date.now();
    }

    /**
     * Initialises the countdown
     */
    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, CHECK_INTERVAL);
        });
    }
    private _revokeInProgress = false;
    /**
     * Checks whether a user should be timed out, and if so, logs them out.
     */
    check() {
        const now = Date.now();
        const timeleft = this.lastAction + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout && !this._revokeInProgress) {
            this.ngZone.run(() => {
                this.oAuthService.logOut();
                this._revokeInProgress = true;
                this.oAuthService.revokeTokenAndLogout();
                this._revokeInProgress = false;
            });
        }
    }
}
